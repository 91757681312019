<template>
  <div>
    <b-tabs
      v-if="userId"
      v-model="tabActive"
      lazy
      pills
      :vertical="$store.getters['app/currentBreakPoint'] === 'xs'"
      content-class="col-12 px-sm-0"
      nav-wrapper-class="col-12 px-sm-0"
      nav-class="nav-left mb-2"
      @activate-tab="onActiveTab"
    >
      <b-tab v-for="tab in cTabs" :key="tab.key" :title="tab.name" lazy>
        <component
          :is="tab.component"
          :ref="tab.ref"
          :user-id="userId"
          :date-select-options="dateSelectOptions"
          :date-selected="dateSelected"
          :page-loading="loading"
          @changeDateSelect="onChangeDateSelect"
          @updatePageLoading="updatePageLoading"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabActive: 0,
      loading: false,
      userId: null,
      dateSelectOptions: [],
      dateSelected: null,
    }
  },
  computed: {
    cTabs() {
      const tabs = [
        {
          key: 'general',
          name: 'ข้อมูลทั่วไป',
          ref: 'refTabGeneral',
          component: () => import('./components/TabGeneralDetails.vue'),
        },
        {
          key: 'transactions',
          name: 'ประวัติค่าคอมมิชชั่นที่ได้รับ',
          ref: 'refTabTransactions',
          component: () => import('./components/TabTransactions.vue'),
        },
        {
          key: 'account',
          name: 'ข้อมูลบัญชี',
          ref: 'refTabAccount',
          component: () => import('./components/TabAccountDetails.vue'),
        },
      ]

      return tabs
    },
  },
  async created() {
    const { query, params } = this.$route
    this.userId = params?.id

    if (query.tab) {
      const idx = this.cTabs.findIndex(v => v.key === query.tab)
      if (idx >= 0) {
        this.tabActive = idx
      } else {
        this.$router.replace({ ...this.$route, query: { tab: this.cTabs[0].key } })
      }
    }

    this.loading = true
    await this.fetchAllDateUserAf(params?.id)
    this.loading = false
  },
  methods: {
    onActiveTab(newTabIndex) {
      const tab = this.cTabs[newTabIndex].key

      this.$router.replace({ ...this.$route, query: { tab } })
    },
    async fetchAllDateUserAf(afId) {
      if (!afId) return
      const resp = await this.api.getV2(`api/admin/affiliate/dashboard/fetch-date/${afId}`).catch(() => null)
      if (resp && resp.code === 200 && resp.data.length > 0) {
        const { data } = resp
        this.dateSelectOptions = [...data]
        // eslint-disable-next-line prefer-destructuring
        this.dateSelected = data[0]
      } else {
        const defaultMonthYear = this.$date().format('YYYY-MM-01')
        this.dateSelectOptions = [defaultMonthYear]
        this.dateSelected = defaultMonthYear
      }
    },

    onChangeDateSelect(date) {
      this.dateSelected = date
    },
    updatePageLoading(val) {
      this.loading = val
    },
  },
}
</script>

<style lang="scss" scoped></style>
